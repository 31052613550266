<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
    aria-hidden="true"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    aria-labelledby="Youtube"
  >
    <path
      d="M22.6 4.7c-.4-.4-.8-.6-2.5-.8-2-.2-4.8-.2-6.9-.2h-2.6c-2.1 0-4.8 0-6.9.2-1.6.1-2 .4-2.5.8C.3 5.7 0 8.5 0 11.3v1.5c0 2.7.3 5.5 1.4 6.5.4.4.8.6 2.5.8 2 .2 4.8.2 6.9.2h2.6c2.1 0 4.8 0 6.9-.2 1.6-.2 2-.4 2.5-.8 1.1-1 1.4-3.7 1.4-6.4v-1.7c-.2-2.8-.5-5.5-1.6-6.5zm-13 11V8.5l6.1 3.4c0 .1-6.1 3.8-6.1 3.8z"
    />
  </svg>
</template>
